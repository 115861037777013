import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Upload is a component that allows users to select and upload files from their device to a server.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/fileupload/fileupload-guideline-anatomy.svg",
      "alt": "anatomy button guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`File Input`}</strong>{`: A button or area that allows users to select files from their local device.`}</li>
      <li parentName="ol"><strong parentName="li">{`Browse Button`}</strong>{`: A button labeled “Browse” or similar, which opens the file dialog when clicked.`}</li>
      <li parentName="ol"><strong parentName="li">{`File List (Optional)`}</strong>{`: A section that displays the selected files’ names or thumbnails.`}</li>
      <li parentName="ol"><strong parentName="li">{`Progress Bar (Optional)`}</strong>{`: A visual indicator showing the progress of file uploads.`}</li>
      <li parentName="ol"><strong parentName="li">{`Cancel Button (Optional)`}</strong>{`: A button that allows users to cancel the upload process for individual files.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Upload component is used in web and application design for the following purposes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`File Uploading`}</strong>{`: Allowing users to upload images, documents, or other files to the system.`}</li>
      <li parentName="ul"><strong parentName="li">{`Content Management`}</strong>{`: Enabling users to add media or files to their profiles, posts, or documents.`}</li>
      <li parentName="ul"><strong parentName="li">{`Attachment Handling`}</strong>{`: Supporting the attachment of files to emails, messages, or forms.`}</li>
    </ul>
    <p><strong parentName="p">{`Common places file uploader appear`}</strong>{` : Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>Users need to upload files to your web application or platform.</li>
      <li>
        You want to simplify the process of selecting and uploading files.
      </li>
      <li>
        Enhancing user experience by providing an intuitive file upload feature
        is a priority.
      </li>
      <li>
        Users need to manage or attach files as part of a task or interaction.
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        File uploading is not a requirement for your application or website.
      </li>
      <li>
        Other methods, like copy-pasting content or typing, are more suitable
        for the task.
      </li>
      <li>
        The user interface is focused on a different type of interaction that
        doesn't involve file uploads.
      </li>
      <li>
        Implementing the Upload component adds unnecessary complexity to the
        user interface.
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Upload components should be accessible to all users, including those with disabilities. To ensure accessibility, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Make sure that the file input and upload button are keyboard accessible.`}</li>
      <li parentName="ul">{`Provide clear and concise labels for the file input and upload button.`}</li>
      <li parentName="ul">{`Add an aria-label attribute to the file input and upload button to provide a descriptive label for screen reader users.`}</li>
      <li parentName="ul">{`Use the aria-valuemin and aria-valuemax attributes to indicate the minimum and maximum file sizes that are allowed to be uploaded, respectively.`}</li>
      <li parentName="ul">{`Use the aria-valuenow attribute to indicate the current progress of the upload to screen reader users.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Provide clear and concise labels for the file input and upload button." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/fileupload/fileupload-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t provide clear and concise labels for the file input and upload button." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/fileupload/fileupload-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When implementing Upload component content, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Clearly label the file input and upload button.`}</li>
      <li parentName="ul">{`Provide visual feedback or a list of uploaded files as users make selections.`}</li>
      <li parentName="ul">{`Offer clear instructions on supported file types, size limits, and any other requirements.`}</li>
      <li parentName="ul">{`Consider providing a cancel button for individual file uploads to enhance user control.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Display a progress bar and status indicator to let users know how long the upload will take." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/fileupload/fileupload-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t display a progress bar and status indicator to let users know how long the upload will take." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/fileupload/fileupload-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      